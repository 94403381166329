import React, { useState, useEffect } from "react";
import Layout from "@components/layouts/Layout";
import SmallBanner from "@components/common/SmallBanner";
import Text from "@components/common/Text";
import Products from "@components/sections/Products";
import dayjs from "dayjs";
import axios from "axios";
import useSWR from "swr";
import ProductsNew from "@components/sections/products2/Products";

const endpoints = {
    products: "/.netlify/functions/products",
};

const Tienda = () => {
    const {
        data: result,
        isLoading,
        isValidating,
    } = useSWR(endpoints.products, axios.get, {
        dedupingInterval: 30 * 1000,
    });
    const [filteredItems, setFilteredItems] = useState([]);

    useEffect(() => {
        if (result?.data?.products) {
            let products = result?.data?.products.filter(
                x => x.enabled && !x.hidden && x?.category?.includes("bulevard"),
            );

            products = products.map(p => {
                const { created_at, updated_at, ...rest } = p;
                return {
                    ...rest,
                    created_at: dayjs(created_at).toDate(),
                    updated_at: dayjs(updated_at).toDate(),
                };
            });

            products = products.sort((a, b) => b.stock - a.stock || b.created_at - a.created_at);

            setFilteredItems(products);
        }
    }, [result]);

    const presenciales = filteredItems?.filter(x => x.category?.includes("face-to-face"));

    return (
        <>
            <Layout
                seo={{
                    title: "Espai Bulevard",
                    description:
                        "Gana seguridad, domina cada intervención y arrasa en el bulevard del ISPC con nuestra formación.",
                }}
            >
                <SmallBanner
                    title="Espai Bulevard"
                    alt="Bulevard"
                    subtitle="Gana seguridad, domina cada intervención y arrasa en el bulevard del ISPC con nuestra formación."
                    filename="bgs/bgBulevard"
                />

                {/** Contenido */}
                <div className="container lg:px-0 pt-10 pb-20 gap-10 grid grid-cols-1">
                    <section className="bg-gray-50 border-b-2 border-gray-300 pb-10">
                        <Text>
                            En nuestros cursos Bulevard te ofrecemos una amplia variedad de cursos
                            presenciales para que puedas formarte de la mejor manera posible. Con
                            profesores especializados y una metodología de enseñanza efectiva,
                            conseguirás los conocimientos necesarios para superar tu oposición.
                        </Text>
                        <div className="mt-10">
                            {process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true" ? (
                                <ProductsNew
                                    items={presenciales}
                                    loading={isLoading || isValidating}
                                />
                            ) : (
                                <Products items={presenciales} />
                            )}
                        </div>
                    </section>
                </div>
            </Layout>
        </>
    );
};

export default Tienda;
